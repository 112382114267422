/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { AccordionGroup, Pane } from './ReactUtils'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDocument } from 'react-firebase-hooks/firestore'
import { firestore } from './firebase'
import { doc, setDoc } from 'firebase/firestore'
import { WhenReady } from './AccessDenied'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { jsPDF } from 'jspdf'
import JsBarcode from 'jsbarcode'
import { BarcodeQuestion } from './Clients'

const SmtpDetailsEdit = React.memo(({ smtpDetails, onChange }) => {
  return <AccordionGroup label="SMTP Details" defaultExpanded={true}>
    <TextField
      label="From Email"
      sx={{ width: '20em' }}
      value={smtpDetails.from || ''}
      onChange={(e) => onChange({ ...smtpDetails, from: e.target.value })}
    />
    <TextField
      label="Host"
      sx={{ width: '20em' }}
      value={smtpDetails.host}
      onChange={(e) => onChange({ ...smtpDetails, host: e.target.value })}
    />
    <TextField
      sx={{ width: '6em' }}
      label="Port"
      value={smtpDetails.port}
      onChange={(e) => onChange({ ...smtpDetails, port: e.target.value })}
    />
    <TextField
      label="Username"
      sx={{ width: '20em' }}
      value={smtpDetails.username}
      onChange={(e) => onChange({ ...smtpDetails, username: e.target.value })}
    />
    <TextField
      label="Password"
      value={smtpDetails.password}
      type={'password'}
      onChange={(e) => onChange({ ...smtpDetails, password: e.target.value })}
    />
  </AccordionGroup>
}, (prev, next) => {
  return prev.smtpDetails.from === next.smtpDetails.from
    && prev.smtpDetails.host === next.smtpDetails.host
    && prev.smtpDetails.port === next.smtpDetails.port
    && prev.smtpDetails.username === next.smtpDetails.username
    && prev.smtpDetails.password === next.smtpDetails.password
})

export function Settings() {
  const [smtpDetails, setSmtpDetails] = useState({
    from: '',
    host: '',
    port: '',
    username: '',
    password: ''
  })
  const [emailTemplate, setEmailTemplate] = useState({
    subject: '',
    body: ''
  })
  const [general, setGeneral] = useState({
    sendEmailsAutomatically: false
  })

  const settingsRef = doc(firestore, 'settings', 'global')

  const [value, loading, error] = useDocument(
    settingsRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  )

  useEffect(() => {
    if (value) {
      const data = value.data()
      if (!data) {
        return
      }
      data.smtpDetails && setSmtpDetails(data.smtpDetails)
      setEmailTemplate(data.emailTemplate || {})
      setGeneral(data.general || {})
    }
  }, [value])

  async function save() {
    await setDoc(settingsRef, {
      smtpDetails,
      emailTemplate,
      general
    }, { merge: true })
  }

  return <Fragment>
    <WhenReady loading={loading} error={error} data={value}>
      <Box style={{ margin: '2em' }}>
        <SmtpDetailsEdit smtpDetails={smtpDetails} onChange={smtpDetails => setSmtpDetails(smtpDetails)}/>
        <AccordionGroup label={'Email Template'} style={{ flexDirection: 'column' }}>
          <TextField
            label="Subject"
            sx={{ flexGrow: 1 }}
            value={emailTemplate.subject}
            onChange={e => setEmailTemplate({ ...emailTemplate, subject: e.target.value })}
          />
          <TextField
            label="Body"
            sx={{ flexGrow: 1 }}
            value={emailTemplate.body}
            onChange={e => setEmailTemplate({ ...emailTemplate, body: e.target.value })}
            multiline={true}
            rows={10}
          />
        </AccordionGroup>
        <AccordionGroup label={'General'} style={{ flexDirection: 'column' }}>
          <FormControlLabel
            label="Send emails right after uploading CSV"
            control={
              <Checkbox
                checked={(typeof general.sendEmailsAutomatically) === 'boolean' ? general.sendEmailsAutomatically : false}
                onChange={e => setGeneral({ sendEmailsAutomatically: e.target.checked })}
              />
            }
          />
        </AccordionGroup>
      </Box>
      <Pane style={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" onClick={() => save()}>Save</Button>
      </Pane>

    </WhenReady>
  </Fragment>
}

