import Button from '@mui/material/Button'
import { signInWithGoogle } from './firebase'
import { Login as LoginIcon } from '@mui/icons-material'
import prk from '../package.json'
import * as React from 'react'

const { version } = prk

export function Login() {
  return <div className={'singleMessagePanel'}>
    <span>{version}</span>
    <Button variant="contained" onClick={signInWithGoogle} style={{ gap: '10px' }}><LoginIcon/>Sign in with
      Google</Button>
  </div>
}
