import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import { useDocument } from 'react-firebase-hooks/firestore'
import { auth, firestore, useUserDetails } from './firebase'
import { doc } from 'firebase/firestore'
import { Settings } from '@mui/icons-material'
import PublishIcon from '@mui/icons-material/Publish'
import prk from '../package.json'
import { Privileged } from './ReactUtils'

const { version } = prk

export function DrawerMenu() {
  const [user] = useUserDetails()
  const [data, loading, error] = useDocument(doc(firestore, 'users', auth.currentUser.email),
    { snapshotListenOptions: { includeMetadataChanges: true } })

  if (!user) {
    return <div/>
  }

  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    {!error && !loading && data && data.data().admin && <MenuItem text="Admin" icon={<Settings/>} to={'/admin'}/>}

    <Privileged access="upload-csv">
      <MenuItem text="Upload CSV" icon={<PublishIcon/>} to={'/upload-csv'}/>
    </Privileged>
    <footer style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
      <span>{version}</span>
    </footer>
  </div>
}

function MenuItem(props) {
  const navigate = useNavigate()
  return <ListItem button onClick={() => navigate(props.to)}>
    <ListItemIcon>
      {props.icon}
    </ListItemIcon>
    <ListItemText primary={props.text}/>
  </ListItem>
}
