/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { ButtonWithQuestion, Pane } from './ReactUtils'
import { Box, FormControl, Tab, Tabs, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { firestore } from './firebase'
import { collection, doc } from 'firebase/firestore'
import { WhenReady } from './AccessDenied'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid'
import { friendlyNameTransform } from 'nemean-server/src/util/Transforms'
import EmailIcon from '@mui/icons-material/Email'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

export function EditUpload() {
  const { id } = useParams()

  const uploadRef = doc(firestore, 'imports', id)

  const [upload, loading, error] = useDocument(
    uploadRef, { snapshotListenOptions: { includeMetadataChanges: true } })

  const data = upload?.data()
  return <Fragment>
    <WhenReady loading={loading} error={error} data={upload}>
      <Pane style={{ gap: '1em', display: 'flex', flexDirection: 'column' }}>
        <FormControl style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em' }}>
          <Field data={data?.timestamp.toDate().toLocaleString()} label="Timestamp"/>
          <Field data={data?.by} label="By"/>
          <Field style={{ minWidth: '300px', flexGrow: 1 }}
                 data={`${data?.status}${data?.reason ? ' - ' + data?.reason : ''}`} label="Status"/>
        </FormControl>

        <Groups uploadRef={uploadRef}/>
      </Pane>
    </WhenReady>
  </Fragment>
}

export function Groups({ uploadRef }) {
  const groupsRef = collection(uploadRef, 'groups')

  const [groups, loading, error] = useCollection(groupsRef)

  let [group, setGroup] = React.useState(null)

  if (!group) {
    group = groups?.docs[0]?.id
  }

  return <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', maxHeight: '700px', overflow: 'auto' }}>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={group}
      onChange={(event, newValue) => setGroup(newValue)}
      sx={{ borderRight: 1, borderColor: 'divider', flexGrow: 1 }}
    >
      {groups?.docs.map((doc) => {
        return <Tab value={doc.id} id={doc.id} label={doc.id} key={doc.id}/>
      })}
    </Tabs>
    {groups?.docs.map((doc, i) => {
      if (doc.id !== group) {
        return
      }
      return <GroupContainer groupsRef={groupsRef} value={doc.id} key={doc.id} index={doc.id}/>
    })}
  </Box>
}

function Field({ data, label, ...props }) {
  return <TextField {...props} InputLabelProps={{ shrink: true }} label={label} value={data || ''}/>
}

function GroupContainer({ groupsRef, value }) {
  const groupRef = doc(groupsRef, value)
  const [groupDoc] = useDocument(
    groupRef, { snapshotListenOptions: { includeMetadataChanges: true } })
  const data = groupDoc?.data()

  async function sendEmail(recipients) {
    try {
      const res = await fetch(`/api/sendEmail?group=${encodeURIComponent(groupRef.path)}&recipients=${recipients}`, { method: 'POST' })
      // showErrorPopup(await res.text())
    } catch (e) {
      console.error(e)
      // showErrorPopup(e.message)
    }
  }


  return (
    <Box sx={{ p: 3, gap: '1em', display: 'flex', flexDirection: 'column', flexGrow: 4 }}>
      <FormControl style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em' }}>
        <Field style={{ minWidth: '300px', flexGrow: 1 }}
               data={data?.recipients} label="Recipients"/>
        <Field style={{ minWidth: '300px', flexGrow: 1 }}
               data={`${data?.status}${data?.reason ? ' - ' + data?.reason : ''}`} label="Status"/>
      </FormControl>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
        <ButtonWithQuestion
          content={
            <span>Please confirm the recipients for the email.</span>}
          label="Recipients"
          title={'Send Email'}
          defaultAnswer={data?.recipients}
          type="text"
          onConfirm={sendEmail}>
          <EmailIcon/> Send Email
        </ButtonWithQuestion>
        <Button href={`/api/pdf?group=${encodeURIComponent(groupRef.path)}`} target="_blank"> <PictureAsPdfIcon/> View
          PDF</Button>
      </Box>
      <Companies groupRef={groupRef}/>
    </Box>
  )
}

function Companies({ groupRef }) {
  const companiesRef = collection(groupRef, 'companies')

  const [companies, loading, error] = useCollection(companiesRef)

  let [company, setCompany] = React.useState(null)

  if (!company) {
    company = companies?.docs[0]?.id
  }

  return <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', gap: '1em', maxSize: '400px', overflow: 'auto' }}>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={company}
      onChange={(event, newValue) => setCompany(newValue)}
      sx={{ borderRight: 1, borderColor: 'divider', flexGrow: 1 }}
    >
      {companies?.docs.map((doc, i) => {
        if (i === 0 && !company) {
          setCompany(doc.id)
        }
        return <Tab id={doc.id} label={doc.id} key={doc.id} value={doc.id}/>
      })}
    </Tabs>
    {companies?.docs.map((doc) => {
      if (doc.id !== company) {
        return
      }
      return <Company companiesRef={companiesRef} value={doc.id} key={doc.id} index={doc.id}/>
    })}
  </Box>
}

const columns = [
  { field: 'account', headerName: friendlyNameTransform.account, flex: 0.3, editable: false },
  { field: 'nemeanAssetId', headerName: friendlyNameTransform.nemeanAssetId, flex: 0.3, editable: false },
  { field: 'clientAssetId', headerName: friendlyNameTransform.clientAssetId, flex: 0.3, editable: false }
]

function Company({ companiesRef, value }) {
  const [rows, setRows] = useState([])
  const companyRef = doc(companiesRef, value)
  const [companyDoc] = useDocument(companyRef)
  const assetsRef = collection(companyRef, 'assets')
  const [assetsCollection] = useCollection(assetsRef)
  const data = companyDoc?.data()

  useEffect(() => {
    if (assetsCollection && assetsCollection.docs && assetsCollection.docs.length) {
      const map = assetsCollection.docs.map(a => {
        const d = a.data()
        return ({
          id: a.id,
          ...d
        })
      })
      setRows(map)
    }
  }, [assetsCollection])

  return (//<Box sx={{ p: 3, gap: '1em', display: 'flex', flexDirection: 'column' }}>
    <DataGrid
      pagination
      autoPageSize
      pageSize={8}
      autoHeight={true}
      columns={columns}
      rows={rows}
      sx={{ flexGrow: 5 }}/>

    // </Box>
  )
}